import "./App.css";
import Home from "./components/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";

function App() {

  document.title = "Administracion - Revitotal Las Heras";

  return (
    <div>

      <Router>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route exact path="/login">
            <Login></Login>
          </Route>
          <Route >
            <Home></Home>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
